// import Solarsystem from "./Solarsystem"
import Project from "./projects"
import Background from "./background"
import Education from "./education"
import Motivation from "./motivation"
import Contact from "./contact"
import Skills from "./Skills"

export default function LandingPage() {
    return (
        <>
        <div className="relative w-full p-20 md:p-40 bg-lime-100 mb-96">
            </div>
        <div className="absolute top-20 md:top-40 md:mt-10 flex flex-col mx-5">
            <div className="lg:h-auto flex-none md:rounded-t-none md:rounded-l overflow-hidden flex justify-center items-center">
                    <img
            className="rounded-full h-60 w-60 m-5"
            src={require("./images/sina-headshot.jpg")}
            ></img>
                </div>
            <h1 className="text-6xl text-center">
                Hi, I am Sina
            </h1>
            <h2 className="text-2xl text-gray-600 text-center">Web Design | Full-Stack Software Developer</h2>
                <p className="lg:p-3 text-center lg:mx-20 mt-5">As a full-stack software developer I explore the wonders of code
                    and the vast reaches of space.
                    Join me on this epic journey as we push boundaries,
                    craft captivating user experiences, and uncover hidden treasures of innovation.
                    Together, let's boldly venture into the unknown, one line of code at a time. </p>
                <p className="p-3 text-center lg:mx-20">See you among the stars!</p>
            <div className="text-center lg:flex md:flex justify-center">
            <Project />
            <Education />
            <Skills />
            <Motivation />
            <Background />
            <Contact />
            </div>
            </div>

        </>
    )
};
