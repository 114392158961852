const InstagramEmbed = () => {
    return (
      <iframe
        src="https://www.instagram.com/sina.in.flux/embed"
        width="400"
        height="500"
        style={{ backgroundColor: "transparent" }}
      ></iframe>
    );
  };

  export default InstagramEmbed;
