import { Fragment, useRef, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


export default function Project() {
//   const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);



    return (
        <>
      <button
        onClick={() => setOpen(true)}
        className="bg-white text-black font-sm
         hover:text-gray-700 p-4 hover:border hover:border-lime-200 hover:rounded-full hover:bg-lime-50
        rounded "
      >
            <img
              className="w-20 h20"
              src={require("./images/planet1.png")}
              alt="motivation-planet"
            />
            projects
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <Dialog.Title className="text-base text-xl text-center font-semibold leading-6 text-gray-900 m-5">
                      My Projects
                    </Dialog.Title>
          <div className="border border-gray-200 hover:border-gray-300 hover:bg-gray-50 mb-5">
            <p className="font-bold p-2">Ocean Beach Brands - Non Alcoholic Online Shop</p>
            <div className=" p-1 lg:h-auto flex-none lg:rounded-t-none lg:rounded-l text-center overflow-hidden p-5">
            <Link
            to="https://oceanbeachbrands.com/"
            >
            <img
              className="border shadow"
              src={require("./images/OBB-homepage.jpg")}
              alt="project"
            />
            </Link>

            </div>
            <div className="flex flex-col text-black p-3">

                <p>
                At Ocean Beach Brands,
                I developed a dynamic online shop for nonalcoholic wines using React and Shopify. This project aimed to enhance the customer shopping experience through a user-friendly interface and efficient navigation.
                I successfully launched a new product line on the Shopify platform, ensuring a seamless purchasing process that showcases the unique offerings and engages the customers effectively.
                  </p>
                <br></br>
                <p className="font-bold">Skills: </p>
             <p className="">React.js, Shopify, JavaScript, Tailwind</p>
                <Link
            to="https://oceanbeachbrands.com/"
            >
            <p className="font-bold text-center text-green-700 animate-pulse">Check out the website</p>
            </Link>

            </div>

            </div>
            <div className="border border-gray-200 hover:border-gray-300 hover:bg-gray-50 mb-5">
            <p className="font-bold p-2">Aging Solutions Website Redesign</p>
            <div className=" p-1 lg:h-auto flex-none lg:rounded-t-none lg:rounded-l text-center overflow-hidden p-5">
            <Link
            to="https://agingsolutions.com/"
            >
            <img
              className="border shadow"
              src={require("./images/ASI-website-for-portfolio.png")}
              alt="project"
            />
            </Link>

            </div>
            <div className="flex flex-col text-black p-3">

                <p>
                For Aging Solutions,
                I designed a modern website mockup using Figma,
                focusing on improved user experience and navigation.
                I then implemented these design updates on their WordPress site via Elementor, ensuring a seamless transition. Additionally,
                I optimized the site's SEO, enhancing its visibility and search engine rankings to attract more traffic. This redesign aimed to both modernize the interface and improve the site's discoverability,
                while maintaining its core focus on accessible care management services.
                  </p>
                <br></br>
                <p className="font-bold">Skills: </p>
             <p className="">Wordpress, Elementor, Figma, CSS</p>
                <Link
            to="https://oceanbeachbrands.com/"
            >
            <p className="font-bold text-center text-green-700 animate-pulse">Check out the website</p>
            </Link>

            </div>

            </div>
            <div className="border border-gray-200 hover:border-gray-300 hover:bg-gray-50">
            <p className="font-bold p-2">Whimzee - Online Shopping Software</p>
            <div className=" p-1 lg:h-auto flex-none lg:rounded-t-none lg:rounded-l text-center overflow-hidden p-5">
            <Link
            to="https://gitlab.com/sinaklughardt/whimzee"
            >
            <img
              className="border shadow"
              src={require("./images/whimzee_page.jpeg")}
              alt="project"
            />
            </Link>

            </div>
            <div className="flex flex-col text-black p-3">

                <p>Whimzee is a user-friendly shop website that facilitates the buying and selling of new and pre-owned items. The platform offers a range of features to enhance the user experience, including user registration, login, and logout functionalities. Once logged in, users have the ability to create their own shop and manage their listings</p>
                <br></br>
                <p className="font-bold">Skills: </p>
             <p className="">FastAPI, PostgreSQL, React.js, Python, JavaScript, Tailwind</p>
                <Link
            to="https://gitlab.com/sinaklughardt/whimzee"
            >
            <p className="font-bold text-center text-green-700 animate-pulse">Check out the repo</p>
            </Link>

            </div>

            </div>

            </div>


                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-black text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-600 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      X
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
    )}
