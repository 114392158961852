import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function YogaNavBar() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/yoga')
        navigate(0)
    }
    return (
      <>
      <nav className="text-gray-600">
      <div className="flex justify-between mx-auto md:py-4 pl-2 md:pr-4 md:pl-4">
      <div className="items-center space-x-5" id="navbar-default">
        <ul className="flex border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
        <li>
            <Link
              to="https://www.instagram.com/sina.in.flux/"
              className="block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0"
              aria-current="page"
            >
          <img
            className="w-8 h8 rounded-full"
            src={require("./images/instagram_logo.png")}
            alt="Instagram Logo"
        />
            </Link>
          </li>
        </ul>
      </div>
      </div>
    </nav>
      </>
    );
};
