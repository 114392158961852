import YogaNavBar from "./YogaNavBar";
import ContactMe from "./YogaContactMe";
import InstagramEmbed from "./YogaInstagramEmbed";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export default function YogaLandingPage() {
    const imageUrl = 'https://www.sinaklughardt.com/static/media/arizona_yoga.ee0824a4294c9a19e376.jpeg';

    return (
        <>
        <Helmet>
            <title>Sina Klughardt Yoga</title>
            <meta property="og:title" content="Sina Klughardt Yoga"/>
            <meta property="og:description" content="Yoga that aligns with nature’s rhythms. Join outdoor yoga classes to feel at home in your body and shine your light." />
            <meta property="og:image" content={imageUrl} />
        </Helmet>
        <YogaNavBar />
        <div className="relative">
            <img
                src={require("./images/arizona_yoga.jpeg")}
                alt="Yoga in Arizona"
                className="w-full h-[400px] md:h-full object-cover"
            />

             <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3 w-full text-center text-white text-2xl md:text-4xl">
                Sina Klughardt Yoga
            </div>
        </div>
        <div className="md:flex md:m-10">
  <div className="md:w-1/2 m-5 flex justify-center md:justify-center">
    <img
      className="rounded h-60 w-60 md:h-80 md:w-80 mt-5 md:mt-0"
      src={require("./images/sina-headshot.jpg")}
      alt="Sina Headshot"
    />
  </div>
    <div className="md:w-1/2 m-5">
        <h1 className="text-4xl">About Me</h1>
        <p className="mt-5 md:mr-10 md:text-lg">
            I am a nature lover, adventure seeker, and passionate yogi who finds deep inspiration in the natural world.
            My journey has taken me through breathtaking national parks, where I connect with the ever-changing landscapes and rhythms of the earth.
        </p>
        <p className="my-3 md:text-lg">
            My path has also led me across continents—from my roots in Germany to building a new home in California, where I continue to grow and explore.
        </p>
    </div>
</div>
<div className="bg-neutral-50 pb-5">
<h1 className="md:text-center ml-5 md:ml-0 text-2xl md:text-4xl pt-5 md:pt-10">My Offerings</h1>
<div className="md:flex md:m-3 h-auto items-center justify-center">

    <div className="md:w-1/2 m-5 flex  lg:justify-end">
        <div className="md:text-end">
            <h1 className="text-4xl md:text-2xl mb-2 ">Nature Flow in GGP</h1>
            <p className="mb-5 ">Unroll your mat and connect with the earth. Every Saturday at 11 AM we come together to move and breathe,
                finding harmony with our natural surroundings.
                All experience levels are welcome.
            </p>
            <button>
                <Link
                    to="https://www.eventbrite.com/e/morning-vinyasa-flow-in-the-park-donation-based-tickets-1252509251889?aff=oddtdtcreator"
                    className="text-white bg-purple-500 hover:bg-purple-700 px-4 py-2 rounded"
                >
                    Sign Up Here
                </Link>
            </button>
        </div>
    </div>

    <div className="md:w-2/3 m-5 flex justify-center">
        <img
            src={require("./images/ggp_flyer.png")}
            alt="Yoga in Golden Gate Park"
            className="md:w-full lg:w-2/3 object-cover"
        />
    </div>
</div>
</div>
<div className="grid place-items-center">
  <ContactMe />
  <InstagramEmbed />
</div>
        </>
    );
}
