import { Fragment, useRef, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { useNavigate } from "react-router-dom";


export default function Skills() {
//   const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

    return (
        <>
      <button
        onClick={() => setOpen(true)}
        className="bg-white text-black font-sm
         hover:text-gray-700 p-4 hover:border hover:border-lime-200 hover:rounded-full hover:bg-lime-50
        rounded "
      >
            <img
              className="w-20"
              src={require("./laptop.jpg")}
              alt="motivation-planet"
            />
            skills
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <Dialog.Title className="text-base textxl text-center font-semibold leading-6 text-gray-900 m-5">
                      My Skills
                    </Dialog.Title>
            <div className="shadow border border-gray-200 hover:border-gray-300">
            <div className="flex m-5 ">
            <img
            className="w-8 h8 mr-4"
            src={require("./python.png")}
            alt="python"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./javascript.webp")}
            alt="js"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./react.png")}
            alt="react"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./html.png")}
            alt="html"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./css.png")}
            alt="css"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./aws.png")}
            alt="aws"
          />
        </div>
        <div className="flex m-5">
        <img
            className="w-8 h8 mr-4 rounded-full "
            src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLcwmLNBMgp-FHKpBsfoxdvnT7mSXKlT-onj-JQRuM&s"}
            alt="gitlab"
          />
          <img
            className="w-8 h8 mr-4"
            src={require("./fastapi.png")}
            alt="fastapi"
          />
          <img
            className="w-8 h8 mr-4"
            src={require("./typescript.png")}
            alt="typescript"
          />
            <img
            className="w-8 h8 mr-4"
            src={require("./django.png")}
            alt="aws"
          />
          <img
            className="w-8 h8 mr-4"
            src={require("./sql.png")}
            alt="sql"
          />
          <img
            className="w-8 h8 mr-4"
            src={require("./mongodb.png")}
            alt="mongodb"
          />
            </div>
            </div>
            </div>

                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-black text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-600 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      X
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
    )}
