import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NavBar from './NavBar';
import LandingPage from './LandingPage';
import YogaLandingPage from './YogaLanding'

function App() {
  return (
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
  );
}

function MainContent() {
  const location = useLocation();
  const hideNavBarRoutes = ["/yoga", "/yoga/"];

  return (
    <>
      {!hideNavBarRoutes.includes(location.pathname) && <NavBar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/yoga" element={<YogaLandingPage />} />
      </Routes>
    </>
  );
}


export default App;
